import { Environment } from './../app/shared/models/environment.interface';

export const environment: Environment = {
  encSecret:'4512631236589784',
  production: false,
  apiGatewayUrl: 'https://uatbeta.etravelprotection.com/es-api',
  claimsUrl: 'https://uat.etravelprotection.com/claims',
  version: '0.0.0',
  tcUrl: 'http://uatpartner.allianztravelinsurance.com/AA/PopUps/TermsAndConditions.aspx',
  purchaseAgreementUrl: 'https://www.agentmaxonline.com/agentmaxweb/documents/GeneralPurchaseTerms.pdf',
  defaultPartnerName: 'allianz',
  etravelDomainUrl: 'https://www.etravelprotection.com',
  eventTicketDomainUrl: 'https://www.eventticketprotection.com',
  eventRegistrationDomainUrl: 'https://www.eventregistrationprotection.com',
  sessionDomainUrl: 'https://www.sessionregistrationprotection.com',
  seasonDomainUrl: 'https://www.seasonpassprotection.com',
  eTravelDomainTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=etravel',
  eventTicketProtectionTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=etp',
  eventRegistrationProtectionTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=erp',
  defaultDomainTagURL: 'https://content.allianzpartnerservices.com/remote-content/v3/privacy-min.js?domain=rickd',
  etravelGoogleTag: 'GTM-TRQQ8VVR',
  eventTicketProtectionGoogleTag: 'GTM-W7DLXJ2',
  eventRegistrationGoogleTag: 'GTM-576TCRX',
  sessionDomainGoogleTag: 'GTM-PR52GCJ',
  seasonDomainGoogleTag: 'GTM-JHZ5P3B'
};
