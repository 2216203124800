import { ContentManagementService } from './../../services/content-management.service';
import { TimeoutService } from './../../services/timeout.service';
import { ESitesNgbDateParserFormatter } from '../../../quote/services/date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailOrPolicySelectionsEnum, DepartureOrPurchaseDateSelectionEnum } from '../../../policy/models/policy-listing.interface';
import { PolicyService } from '../../../policy/services/policy.service';
import { AppStateService } from '../../services/app-state.service';
import { UtilService } from '../../services/util.service';
import { AlertService } from '../../alert/services/alert.service';
import * as _ from 'lodash';
import { PolicyListing } from '../../../policy/models/index';
import { NavigationEnum } from '../../../components/navbar/navigation.enum';
import { HttpErrorResponse } from '@angular/common/http';
import * as AlertMessages from '../../constants/alert-messages.constants';
import { Site } from '../../models/index';
import { Observable } from 'rxjs';
import { DomainTypeEnum } from '../../../policy/models/domain-type.enum';
import { ButtonEnum } from '../../../quote/models/button.enum';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';

@Component({
  selector: 'app-manage-policy',
  templateUrl: './manage-policy.component.html',
  styleUrls: ['./manage-policy.component.scss'],
  providers: [
    PolicyService
  ]
})
export class ManagePolicyComponent implements OnInit {
  public colorObs: Observable<Site>;
  public primaryButtonColor: string;
  public secondaryButtonColor: string;
  public managePolicyForm: FormGroup;
  public dateTypeSelectionLabel: string;
  public dateTypeSelections: Array<Object>;
  public selectedDateLabel: string;
  public isSessionSeason = false;

  public emailOrPolicySelections = [
    { value: 1, name: 'Email Address' },
    { value: 2, name: 'Policy Number' }
  ];
  public departureOrPurchaseDateSelections = [
    { value: 1, name: 'Departure Date' },
    { value: 2, name: 'Purchase Date' },
  ];

  public eventBeginOrPurchaseDateSelections = [
    { value: 1, name: 'Event Begin Date' },
    { value: 2, name: 'Purchase Date' }
  ];

  public activityBeginOrPurchaseDateSelections = [
    { value: 1, name: 'Activity Begin Date' },
    { value: 2, name: 'Purchase Date' }
  ];

  @Output() policyListingsReceived: EventEmitter<Array<PolicyListing>> = new EventEmitter();

  public errors: string[] = [];
  public buttonDisabled = false;
  public selectionLabel = [];

  constructor(private formBuilder: FormBuilder,
    private policyService: PolicyService,
    private appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private timeoutService: TimeoutService,
    private utilService: UtilService,
    private contentManagementService: ContentManagementService,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.managePolicyForm = this.formBuilder.group({
      emailOrPolicySelection: [_.head(this.emailOrPolicySelections).value, Validators.required],
      emailOrPolicy: ['', [Validators.required]],
      departureOrPurchaseDateSelection: [_.head(this.departureOrPurchaseDateSelections).value, Validators.required],
      eventbegineOrPurchaseDateSelection: [_.head(this.eventBeginOrPurchaseDateSelections).value, Validators.required],
      departureOrPurchaseDate: ['', Validators.required]
    });
  }

  ngOnInit() {
    const sessionSearchForm = this.appStateService.getPolicySearchForm();

    this.colorObs = this.contentManagementService.tryGetAgencyConfig();

    if (typeof (sessionSearchForm) !== 'undefined' && sessionSearchForm !== null) {
      this.managePolicyForm.setValue(sessionSearchForm);
    }

    this.setLabelNames(this.appStateService.getPartnerConfig().domain);

  }

  // Set date selections dropdown label
  setLabelNames(domain: string) {
    switch (domain) {
      case DomainTypeEnum.Travel:
        this.dateTypeSelections = this.departureOrPurchaseDateSelections;
        this.dateTypeSelectionLabel = 'Departure or Purchase Date';
        this.selectionLabel = this.departureOrPurchaseDateSelections;
        break;
      case DomainTypeEnum.EventTicketProtection:
      case DomainTypeEnum.EventRegistration:
        this.dateTypeSelections = this.eventBeginOrPurchaseDateSelections;
        this.dateTypeSelectionLabel = 'Event Begin Date or Purchase Date';
        this.selectionLabel = this.eventBeginOrPurchaseDateSelections;
        break;
      case DomainTypeEnum.Session:
      case DomainTypeEnum.Season:
        this.dateTypeSelections = this.activityBeginOrPurchaseDateSelections;
        this.dateTypeSelectionLabel = 'Activity Begin Date or Purchase Date';
        this.selectionLabel = this.activityBeginOrPurchaseDateSelections;
        this.isSessionSeason = true;
        break;
    }
  }

  getDateSelectionLabel() {
    return this.selectionLabel.find(v => v.value == this.departureOrPurchaseDateSelection.value).name;
  }

  getEmailOrPolicyNumberLabel() {
    return this.emailOrPolicySelections.find(v => v.value == this.emailOrPolicySelection.value).name;
  }

  onSubmit() {
    this.errors = [];
    if (this.managePolicyForm.valid) {
      this.buttonDisabled = true;
      const emailOrPolicyVal = this.emailOrPolicy.value;
      const emailOrPolicy = this.emailOrPolicySelection.value;
      const departureOrPurchaseVal = this.departureOrPurchaseDate.value;
      const departureOrPurchase = this.departureOrPurchaseDateSelection.value;
      this.policyService.searchPolicy(
        emailOrPolicyVal,
        emailOrPolicy,
        departureOrPurchaseVal,
        departureOrPurchase
      ).subscribe(pqr => {
        let polListings = [];
        if (!pqr.isValid) {
          // Validation Errors
          this.errors = pqr.errorMessages;
          this.buttonDisabled = true;
        } else {
          switch (pqr.policies.length) {
            case 0:
              this.errors = [AlertMessages.NO_POLICIES];
              this.buttonDisabled = false;
              break;
            case 1:
            default:
              // 1 or more policies found
              this.ga.formSubmit(ButtonEnum.ManagePolicy);
              this.appStateService.clearManagePolicyState();
              this.appStateService.clearPurchaseState();
              this.appStateService.setManageSessionActive(true);
              this.appStateService.setPolicySearchForm(this.managePolicyForm.value);
              this.appStateService.toggleActiveLink(NavigationEnum.MyPolicies, true);

              this.timeoutService.startSessionTimer();
              polListings = pqr.policies;
              break;
          }
          this.buttonDisabled = false;
          this.policyListingsReceived.emit(polListings);
        }
      },
        (err: HttpErrorResponse) => {
          this.policyListingsReceived.emit([]);
          if (err.status === 400) {
            if (err.error.modelState) {
              this.errors = err.error.modelState.errorMessages.map(e => e.description);
            }
          }
          if (this.errors.length === 0) {
            this.errors = [AlertMessages.SERVICE_DOWN];
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.managePolicyForm);
    }
  }

  get emailOrPolicySelection() { return this.managePolicyForm.get('emailOrPolicySelection'); }
  get departureOrPurchaseDateSelection() { return this.managePolicyForm.get('departureOrPurchaseDateSelection'); }
  get eventBeginOrPurchaseDateSelection() { return this.managePolicyForm.get('departureOrPurchaseDateSelection'); }
  get emailOrPolicy() { return this.managePolicyForm.get('emailOrPolicy'); }
  get departureOrPurchaseDate() { return this.managePolicyForm.get('departureOrPurchaseDate'); }
  get friendlyEmailOrPolicySelection(): string {
    return this.emailOrPolicySelection.value === Number(EmailOrPolicySelectionsEnum.EmailAddress)
      ? `Email Address`
      : `Policy Number`;
  }
}
